<template>
  <div :class="$style.wrap">
    <div :class="$style.inner">
      <slot name="pre" />
      <div>
        <slot name="preMember" />
        <family-member
          :member="person"
          :emphasize-name="emphasizeName"
          :to="to"
        />
        <slot name="postMember" />
      </div>
      <div :class="indent ? $style.indent : null">
        {{ person.street }} {{ person.streetNumber }},
        {{ person.zip }}
        <karlsruhe-locality :locality-and-district="person" />
        <slot name="postAddress" />
      </div>
      <div :class="indent ? $style.indent : null">
        <router-link
          :is="infoTag"
          :to="{
            name: 'family',
            params: { familyId: person.familyId },
            query: {
              personId: person.id,
              tab: 'passports',
            },
          }"
          :target="linkTarget"
          v-text="passports"
        />,
        <router-link
          :is="infoTag"
          :to="{
            name: 'family',
            params: { familyId: person.familyId },
            query: {
              personId: person.id,
              tab: 'documents',
            },
          }"
          :target="linkTarget"
          v-text="documents"
        />
      </div>
    </div>
    <div
      v-if="$slots.actions"
      :class="[$style.actions, indent ? $style.indent : null]"
    >
      <slot name="actions" />
    </div>
  </div>
</template>

<script>
import FamilyMember from "~/src/components/families/FamilyMember";
import KarlsruheLocality from "~/src/components/common/i18n/KarlsruheLocality";

export default {
  name: "FamilyRelationDetail",
  components: { KarlsruheLocality, FamilyMember },
  props: {
    person: {
      type: Object,
      required: true,
    },
    noLinks: {
      type: Boolean,
      default: false,
    },
    to: {
      type: [String, Object],
      default: null,
    },
    linkTarget: {
      type: String,
      default: "_self",
    },
    emphasizeName: {
      type: Boolean,
      default: false,
    },
    indent: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    infoTag() {
      const { noLinks } = this;
      if (noLinks) {
        return "span";
      }
      return "router-link";
    },
    applications() {
      const { person } = this;
      if (person && "applicationsAsApplicantCount" in person) {
        if (person.applicationsAsApplicantCount === 1) {
          return "1 Antrag";
        }
        return `${person.applicationsAsApplicantCount} Anträge`;
      } else {
        return "Anträge";
      }
    },
    passports() {
      const { person } = this;
      if (person && "passportsCount" in person) {
        if (person.passportsCount === 1) {
          return "1 Pass";
        }
        return `${person.passportsCount} Pässe`;
      } else {
        return "Pässe";
      }
    },
    documents() {
      const { person } = this;
      if (person && "documentsCount" in person) {
        if (person.documentsCount === 1) {
          return "1 Dokument";
        }
        return `${person.documentsCount} Dokumente`;
      } else {
        return "Dokumente";
      }
    },
  },
};
</script>

<style module lang="module.css">
.wrap {
  display: flex;
  align-items: flex-start;
}
.wrap + .wrap {
  margin-top: 2rem;
}
.inner {
  flex: 1;
  line-height: normal;
}
.actions {
  margin-left: 10px;
}
.indent {
  margin-left: 56px;
}
</style>
