<template>
  <el-table-column width="64" prop="comments" align="center" :fixed="fixed">
    <i slot="header" class="el-icon-s-comment" :class="$style.label" />
    <template #default="{ row }">
      <comment-popover
        :commentable="subKey ? row[subKey] : row"
        @update="$emit('update', $event)"
      />
    </template>
  </el-table-column>
</template>

<script>
import CommentPopover from "~/src/components/comments/CommentPopover";

export default {
  name: "CommentColumn",
  components: { CommentPopover },
  props: {
    subKey: {
      type: String,
      default: null,
    },
    fixed: {
      type: [Boolean, String],
      default: false,
    },
  },
};
</script>

<style module lang="module.css">
.label {
  transform: scale(1.6);
}
</style>