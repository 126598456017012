<template>
  <span v-if="hasInfo">
    <template v-if="page && pages > 1">
      Seite {{ $filters.number2Human(page) }}/{{ $filters.number2Human(pages) }}
      –
    </template>
    {{ $filters.number2Human(totalCount) }} Ergebnisse
  </span>
</template>

<script>
export default {
  name: "ResultInfo",
  props: {
    totalCount: {
      type: Number,
      default: null,
    },
    pageInfo: {
      type: Object,
      default: null,
    },
    perPage: {
      type: Number,
      default: 10,
    },
  },
  computed: {
    hasInfo() {
      const { totalCount } = this;
      return !!totalCount;
    },
    page() {
      const { pageInfo, perPage } = this;
      if (!pageInfo || !pageInfo.endCursor || !perPage) {
        return null;
      }
      const endIndex = +atob(pageInfo.endCursor);
      return Math.ceil(endIndex / perPage);
    },
    pages() {
      const { totalCount, perPage } = this;
      if (!totalCount || !perPage) {
        return null;
      }
      return Math.ceil(totalCount / perPage);
    },
  },
};
</script>

<style lang="module.scss" module></style>
