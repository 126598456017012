export default {
  name: "ReloadablePage",
  watch: {
    $route: "reloadOnRouteChange",
  },
  beforeCreate() {
    this._reloadMethods = [];
  },
  methods: {
    addToReloadMethods(m) {
      this._reloadMethods.push(m);
    },
    reload() {
      this._reloadMethods.forEach((m) => {
        m();
      });
    },
    reloadOnRouteChange(newRoute, oldRoute) {
      this.reload();
    },
  },
};
