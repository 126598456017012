<template>
  <el-container
    v-loading.fullscreen.lock="$auth.loggingIn"
    class="Layout"
    direction="vertical"
  >
    <el-header class="AppHeader" :class="$style.header">
      <router-link
        :to="{ name: 'index' }"
        class="AppHeader__logo"
        aria-label="Zur Startseite"
      >
        <logo :height="52" />
      </router-link>
      <el-tooltip v-if="envNotProduction">
        <div slot="content">
          Dies ist nicht die Produktivumgebung sondern die
          {{ env }}-Umgebung.<br />
          Hier darf probiert werden – ohne Angst haben zu müssen dass etwas
          kaputt geht.<br />
          <em>Achtung: Bitte keine wirklichen/produktiven Daten verwenden!</em>
        </div>
        <el-tag :class="$style.envInfo" type="warning" effect="dark">
          {{ env }}
        </el-tag>
      </el-tooltip>
      <span class="AppHeader__fill" />
      <el-menu
        ref="menu"
        :default-active="activeKey"
        background-color="transparent"
        class="Menu"
        mode="horizontal"
      >
        <menu-link v-show="$root.loggedIn" :to="{ name: 'index' }" only-exact>
          <i class="el-icon-s-home" />
        </menu-link>

        <el-submenu
          v-show="$root.hasRightPassportRead"
          index="passport-management-menu"
          popper-class="AppHeader__submenu"
        >
          <template #title>
            <i class="el-icon-s-ticket" />
            Passausgabe
          </template>
          <menu-link
            :to="{ name: 'families' }"
            @navigated="$refs.menu.close('passport-management-menu')"
          >
            <i class="el-icon- ka-icon-users" /> Familien/Personen
          </menu-link>
          <menu-link
            :to="{ name: 'applications' }"
            @navigated="$refs.menu.close('passport-management-menu')"
          >
            <i class="el-icon-s-order" /> Anträge
          </menu-link>
          <menu-link
            :to="{ name: 'passports' }"
            @navigated="$refs.menu.close('passport-management-menu')"
          >
            <i class="el-icon-s-ticket" /> Pässe
          </menu-link>
          <menu-link
            v-if="hasSdika"
            :to="{ name: 'digital-passports' }"
            @navigated="$refs.menu.close('passport-management-menu')"
          >
            <i class="el-icon-s-ticket" /> Digitale Pässe
          </menu-link>
        </el-submenu>

        <menu-link
          v-show="$root.hasRightStatistics"
          :to="{ name: 'statistics' }"
        >
          <i class="el-icon- ka-icon-chart-pie" />
          Statistik
        </menu-link>

        <el-submenu
          v-show="$root.hasRightEmployeeManagement"
          index="management-menu"
          popper-class="AppHeader__submenu"
        >
          <template #title>
            <i class="el-icon-s-tools" />
          </template>
          <menu-link
            :to="{ name: 'perks' }"
            @navigated="$refs.menu.close('management-menu')"
          >
            <i class="el-icon-s-ticket" /> Extras
          </menu-link>
          <menu-link
            v-show="$root.hasRightEmployeeManagement"
            :to="{ name: 'employees' }"
            @navigated="$refs.menu.close('management-menu')"
          >
            <i class="el-icon-s-custom" /> Mitarbeitende
          </menu-link>
        </el-submenu>

        <el-submenu
          v-show="$root.loggedIn"
          index="user-menu"
          popper-class="AppHeader__submenu"
        >
          <template #title>
            <i class="el-icon-user-solid" />
          </template>
          <menu-link
            v-show="$root.loggedIn"
            :to="{ name: 'profile' }"
            @navigated="$refs.menu.close('user-menu')"
          >
            <i class="el-icon-setting" />
            Mein Profil
          </menu-link>
          <el-menu-item class="has-link" index="printer-setup">
            <el-badge
              type="primary"
              :value="printerQueueLength"
              :hidden="printerQueueLength === 0"
            >
              <button type="button" @click.prevent.stop="showPrinterSetup">
                <i class="el-icon- ka-icon-print" /> Drucker-Setup
              </button>
            </el-badge>
          </el-menu-item>
          <el-menu-item class="has-link" index="logout">
            <button type="button" @click.prevent.stop="logout">
              <i class="el-icon-switch-button" />
              Logout
            </button>
          </el-menu-item>
        </el-submenu>
      </el-menu>
    </el-header>
    <el-container direction="vertical" :class="$style.main">
      <transition mode="out-in" name="trans-fade">
        <router-view />
      </transition>
    </el-container>
    <!--    <app-footer :class="$style.footer" />-->
    <img
      alt=""
      class="Layout__footer-visual u-do-not-print"
      src="~images/unten.svg"
    />
    <comment-drawer ref="commentDrawer" />
    <file-drawer ref="fileDrawer" />
  </el-container>
</template>

<script>
import Logo from "~/src/components/common/layout/Logo";
import MenuLink from "~/src/components/common/layout/MenuLink";
import CommentDrawer from "~/src/components/comments/CommentDrawer";
import { RAILS_ENV } from "~/src/util/rails.js.erb";
import FileDrawer from "~/src/components/common/uploads/FileDrawer";
import { ReactiveQueue, showPrinterSetup } from "~/src/util/web2print";
import Command from "~/src/components/mixins/Command";
import { hasSdika } from "~/src/util/feature-flags";

export default {
  name: "Frontend",
  components: { FileDrawer, CommentDrawer, MenuLink, Logo },
  mixins: [Command],
  provide() {
    return {
      openCommentDrawer: (record) => {
        return this.openCommentDrawer(record);
      },
      openFileDrawer: (file) => {
        return this.openFileDrawer(file);
      },
    };
  },
  data() {
    return {
      badges: {},
      activeKey: null,
      pathPrefixes: [],
    };
  },
  head: {
    titleTemplate: "%s - KA Pass Backend",
    description: `Mit dieser Website können stja-Mitarbeitende Karslruhe Pass und Kinderpass ausstellen und verwalten.`,
  },
  computed: {
    loggedIn() {
      return this.$auth.loggedIn;
    },
    notLoggedIn() {
      return !this.$auth.loggedIn;
    },
    authError() {
      return this.$auth.error;
    },
    env() {
      return RAILS_ENV;
    },
    envNotProduction() {
      const { env } = this;
      return env !== "production";
    },
    printerQueueLength() {
      return ReactiveQueue.queue.length;
    },
    hasSdika() {
      return hasSdika();
    },
  },
  watch: {
    $route: "syncMenuState",
  },
  mounted() {
    if (this.authError) {
      console.log("authError", this.authError);
      return this.$msgbox({
        title: "Fehler",
        message:
          "Es ist ein Fehler beim Authentifizieren aufgetreten – sie wurden ausgeloggt" +
          this.authError,
      })
        .catch(() => false)
        .finally(() => {
          return this.$router.replace({ name: "login" });
        });
    }
    this.refreshBadges();
    Object.keys(this.$refs.menu.items).forEach((key) => {
      if (["logout", "printer-setup"].indexOf(key) > -1) {
        return;
      }
      const { href } = this.$router.resolve({ name: key });
      this.pathPrefixes.push({ key, href });
    });
    this.pathPrefixes.sort(function (a, b) {
      return b.href.length - a.href.length;
    });
    this.syncMenuState();
  },
  methods: {
    syncMenuState() {
      let activeKey = this.activeKey;
      for (let i = 0; i < this.pathPrefixes.length; i++) {
        const p = this.pathPrefixes[i];
        if (this.$route.path.startsWith(p.href)) {
          activeKey = p.key;
          break;
        }
      }
      this.activeKey = activeKey;
    },
    refreshBadges() {},
    logout() {
      this.$auth.logout().finally(() => location.reload());
    },
    openCommentDrawer(record) {
      return this.$refs.commentDrawer.openFor(record);
    },
    openFileDrawer(file) {
      return this.$refs.fileDrawer.openFor(file);
    },
    showPrinterSetup() {
      return showPrinterSetup();
    },
  },
};
</script>

<style module lang="module.css">
.header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 20;
}

.envInfo {
  margin-left: 1rem;
  align-self: center;
}

.main {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-bottom: 20px;
}

.main :global(.Content.Content.Content) {
  min-height: auto;
  padding-top: 80px;
  overflow: visible;
}
</style>
