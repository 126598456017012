import { supportsEmoji } from "~/src/util/detect";
import { EU_MEMBERS } from "~/src/data/eu_members";
import CODES_TO_NAMES from "~/src/data/country_codes_to_names.json";
import CODES_TO_FLAGS from "~/src/data/country_codes_to_flags.json";

export function getLabel(code, unknown = "unbekanntes Land (%s)") {
  const isEu = EU_MEMBERS.indexOf(code) > -1;
  const flag = CODES_TO_FLAGS[code];
  if (supportsEmoji() && flag) {
    return `${CODES_TO_NAMES[code] || unknown.replace("%s", code)} ${flag}${
      isEu ? " (EU)" : ""
    }`;
  } else {
    return `${CODES_TO_NAMES[code] || unknown.replace("%s", code)}${
      isEu ? " (EU)" : ""
    }`;
  }
}
export function getCode(code) {
  const isEu = EU_MEMBERS.indexOf(code) > -1;
  const flag = CODES_TO_FLAGS[code];
  if (supportsEmoji() && flag) {
    return `${flag}`;
  } else {
    return `${code}`;
  }
}
