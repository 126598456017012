<template>
  <el-dialog
    v-loading="loading"
    width="300px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    :destroy-on-close="true"
    :visible.sync="vis"
    title="Erneut einloggen"
    center
  >
    <el-alert
      :closable="false"
      :type="alertType"
      show-icon
      :class="$style.alert"
      :description="alertText"
    />
    <el-form
      ref="form"
      label-position="top"
      :model="model"
      :rules="{
        password: { required: true, message: 'Notwendig', trigger: 'blur' },
      }"
      @submit.native.prevent
      v-if="!needs2FA"
    >
      <el-form-item label="Passwort" prop="password">
        <el-input
          ref="input"
          v-model="model.password"
          type="password"
          @keypress.native.enter="submit().catch(() => {})"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit().catch(() => {})">
          Login
        </el-button>
      </el-form-item>
    </el-form>
    <el-form
      v-else
      ref="form2fa"
      :model="model"
      :rules="{
        code: { required: true, message: 'Notwendig', trigger: 'blur' },
      }"
      label-position="top"
      @submit.native.prevent
    >
      <el-alert
        :class="$style.alert"
        :closable="false"
        effect="dark"
        type="info"
        show-icon
      >
        Wir haben Ihnen gerade eine E-Mail mit einem Code geschickt. Bitte geben
        Sie diesen Code hier ein. Sie haben 15 Minuten dafür Zeit.
      </el-alert>
      <el-form-item label="Code" prop="code">
        <el-input
          ref="codeInput"
          v-model="model.code"
          @keypress.native.enter="submit2fa().catch(() => {})"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit2fa().catch(() => {})">
          Login
        </el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import Overlay from "~/src/components/mixins/Overlay";
import { ensureValidForm } from "~/src/util/validation";

export default {
  name: "ReLoginOverlay",
  mixins: [Overlay],
  props: {
    email: {
      type: String,
      required: true,
    },
    error: {
      type: String,
      default: null,
    },
    doAuthenticate: {
      type: Function,
      required: true,
    },
    do2fa: {
      type: Function,
      required: true,
    },
    needs2FA: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: 0,
      model: {
        password: "",
        code: "",
      },
    };
  },
  computed: {
    alertType() {
      const { error } = this;
      return error ? "error" : "warning";
    },
    alertText() {
      const { error } = this;
      return (
        error || "Sie müssen sich erneut einloggen, um fortfahren zu können"
      );
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.input.$el.focus();
    });
  },
  methods: {
    submit() {
      return ensureValidForm(this.$refs.form, () => {
        this.loading += 1;
        return this.doAuthenticate({
          email: this.email,
          password: this.model.password,
        })
          .then(() => {
            this.loading -= 1;
          })
          .catch(() => {
            this.loading -= 1;
            this.$refs.form.resetFields();
            this.$nextTick(() => {
              this.$refs.form.clearValidate();
            });
          });
      });
    },
    submit2fa() {
      return ensureValidForm(this.$refs.form2fa, () => {
        this.loading += 1;
        const { code } = this.model;
        return this.do2fa({ code })
          .then((...args) => {
            this.loading -= 1;
          })
          .catch(() => {
            this.loading -= 1;
            this.$refs.form2fa.resetFields();
            this.$nextTick(() => {
              this.$refs.form2fa.clearValidate();
            });
          });
      });
    },
  },
};
</script>

<style module lang="module.css">
.alert.alert {
  margin-bottom: 1rem;
}
</style>