<script>
import { getCityDistrictName } from "./CityDistrictName";

export default {
  name: "KarlsruheLocality",
  functional: true,
  props: {
    localityAndDistrict: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  render(createElement, context) {
    const { localityAndDistrict } = context.props;
    if (
      localityAndDistrict.locality === "Karlsruhe" &&
      localityAndDistrict.cityDistrictNumber &&
      localityAndDistrict.cityDistrictNumber !== "?" &&
      localityAndDistrict.cityDistrictNumber !== "PO"
    ) {
      return createElement(
        "span",
        getCityDistrictName(localityAndDistrict.cityDistrictNumber)
      );
    } else {
      return createElement("span", localityAndDistrict.locality);
    }
  },
};
</script>
