// https://raw.githubusercontent.com/Modernizr/Modernizr/master/feature-detects/emoji.js

let _supportsEmoji = null;

export function supportsEmoji() {
  if (_supportsEmoji === null) {
    const pixelRatio = window.devicePixelRatio || 1;
    const offset = 12 * pixelRatio;
    const node = document.createElement("canvas");
    const ctx = node.getContext("2d");
    ctx.fillStyle = "#f00";
    ctx.textBaseline = "top";
    ctx.font = "32px Arial";
    ctx.fillText("\ud83d\udc28", 0, 0); // U+1F428 KOALA
    _supportsEmoji = ctx.getImageData(offset, offset, 1, 1).data[0] !== 0;
  }
  return _supportsEmoji;
}
