<template>
  <el-main class="Content Content_centered">
    <app-panel :class="$style.panel" main v-loading.fullscreen.lock="loading">
      <h1 slot="title" class="AppTitle">E-Mail überprüfen</h1>
    </app-panel>
  </el-main>
</template>

<script>
import AppPanel from "../../components/common/layout/AppPanel";
import { getErrorMsg, showError } from "~/src/util/validation";
import gql from "graphql-tag";
import { ErrorFragment } from "~/src/graphql/fragments";

export default {
  components: { AppPanel },
  validate({ query }) {
    const { confirmation_token } = query;
    return !!confirmation_token;
  },
  data() {
    return {
      loading: true,
    };
  },
  head() {
    return {
      title: "E-Mail überprüfen",
    };
  },
  mounted() {
    this.checkToken();
  },
  methods: {
    checkToken() {
      this.loading = true;
      const { confirmation_token } = this.$route.query;
      return this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation confirmAccount($confirmationToken: String!) {
              confirmAccount(input: { confirmationToken: $confirmationToken }) {
                ...ErrorFragment
              }
            }
            ${ErrorFragment}
          `,
          // Parameters
          variables: {
            confirmationToken: confirmation_token,
          },
        })
        .then(({ data: { confirmAccount } }) => {
          // Result
          if (!confirmAccount) {
            const change = false;
            return this.$msgbox({
              type: "success",
              title: change
                ? "E-Mail-Adresse geändert"
                : "E-Mail-Adresse überprüft",
              message: change
                ? `Wir haben erfolgreich Ihre E-Mail-Adresse zu ${r.result.email} geändert.`
                : "Ihre Registrierung ist nun abgeschlossen",
            })
              .catch(() => false)
              .finally(() => {
                return this.login();
              });
          } else {
            return this.$msgbox({
              type: "warning",
              title: "Fehler",
              message: getErrorMsg(
                confirmAccount.key,
                confirmAccount.details,
                confirmAccount.messages
              ),
            })
              .catch(() => false)
              .finally(() => {
                return this.login();
              });
          }
        })
        .catch((error) => {
          // Error
          console.error(error);
          return showError(error);
        });
    },
    login() {
      if (this.$root.loggedIn) {
        return this.$router.push({ name: "index" });
      }
      return this.$router.push({ name: "login" });
    },
  },
};
</script>

<style lang="module.scss" module>
.panel {
  width: 400px;
  max-width: calc(100vw - 40px);
}
</style>