<template>
  <app-panel :no-border="noBorder">
    <div v-if="$slots.filter" :class="$style.filter">
      <slot name="filter" />
    </div>
    <el-table
      ref="table"
      v-loading="loading"
      :data="list"
      row-key="id"
      :size="size"
      stripe
      :height="tableHeight"
    >
      <el-table-column label="Id" prop="identifier" width="110">
        <template #default="{ row }">
          {{ row.identifier }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="!hideStatus"
        label="Status"
        prop="status"
        width="105"
      >
        <template #default="{ row }">
          <application-status :status="row.status" />
        </template>
      </el-table-column>
      <el-table-column
        v-if="!hideApplicant"
        label="Antragsteller*in"
        prop="applicant"
        min-width="280"
      >
        <template #default="{ row }">
          <family-relation-detail
            :person="row.applicant"
            :to="{
              name: 'family',
              params: {
                familyId: row.applicant.familyId,
              },
              query: {
                personId: row.applicant.id,
                tab: 'applications',
              },
            }"
          />
        </template>
      </el-table-column>
      <el-table-column label="Beantragt" prop="items" min-width="300">
        <template #default="{ row }">
          <application-items-summary :application="row" /><br />
          {{ row.notices.join(", ") }}
        </template>
      </el-table-column>
      <el-table-column label="Erstellt" prop="createdAt" width="92">
        <template #default="{ row }">
          <locale-date-time :date="row.createdAt" two-lines />
        </template>
      </el-table-column>
      <slot :executeCommand="executeCommand">
        <el-table-column />
      </slot>
      <comment-column :fixed="commentColumnFixed" @update="reload" />
      <template slot="empty">
        <slot name="empty" />
      </template>
    </el-table>
    <data-table-footer
      :has-next-page="hasNextPage"
      :has-previous-page="hasPreviousPage"
      :reload="reload"
      @next-page="goToNextPage"
      @previous-page="goToPreviousPage"
    >
      <template slot="actions">
        <slot name="actions" />
      </template>
      <result-info
        slot="results"
        :total-count="totalCount"
        :page-info="pageInfo"
      />
    </data-table-footer>
  </app-panel>
</template>

<script>
import Command from "~/src/components/mixins/Command";
import LocaleDateTime from "~/src/components/common/i18n/LocaleDateTime";
import AppPanel from "~/src/components/common/layout/AppPanel";
import DataTableFooter from "~/src/components/common/list/DataTableFooter";
import DataTableParent from "~/src/components/mixins/DataTableParent";
import CommentColumn from "~/src/components/comments/CommentColumn";
import ApplicationStatus from "~/src/components/applications/ApplicationStatus";
import ApplicationItemsSummary from "~/src/components/applications/ApplicationItemsSummary";
import FamilyMember from "~/src/components/families/FamilyMember";
import ResultInfo from "~/src/components/common/list/ResultInfo";
import FamilyRelationDetail from "~/src/components/families/FamilyRelationDetail";

export default {
  name: "ApplicationList",
  components: {
    FamilyRelationDetail,
    ResultInfo,
    FamilyMember,
    ApplicationItemsSummary,
    ApplicationStatus,
    CommentColumn,
    DataTableFooter,
    AppPanel,
    LocaleDateTime,
  },
  mixins: [Command, DataTableParent],
  props: {
    hideStatus: {
      type: Boolean,
      default: false,
    },
    hideApplicant: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style module lang="module.css">
.filter {
  composes: filterWrap from '~stylesheets/base.module.css';
}
</style>
