<template>
  <el-main :class="$style.container" class="Content Content_centered">
    <div>
      <logo :class="$style.logo" />
      <div :class="$style.whatIsThis">
        <el-alert
          :closable="false"
          effect="dark"
          title="404"
          type="warning"
          show-icon
        >
          Diese Seite kennen wir nicht.
        </el-alert>
      </div>
    </div>
  </el-main>
</template>

<script>
import Logo from "../components/common/layout/Logo.vue";

export default {
  components: {
    Logo,
  },
  head: {
    title: "Seite nicht gefunden",
  },
  methods: {
    profile() {
      return this.$router.push("/me");
    },
  },
};
</script>

<style lang="module.scss" module>
.container {
  /*text-align: center;*/
}

.logo {
  margin-top: 30px;
  width: 50vw;
  min-width: 250px;
  max-width: 400px;
  height: auto;
}

.whatIsThis {
  padding: 15px 10px 0;
  max-width: 600px;

  > * {
    margin-top: 1em;

    & :first-child {
      margin-top: 0;
    }

  }
}
</style>
