<script>
import { escapeRegExp } from "lodash/string";

export default {
  name: "QueryHighlighter",
  functional: true,
  props: {
    query: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
  },
  render(createElement, context) {
    const { query, text } = context.props;
    const normalizeQuery = (query || "").trim().replace(/\s+/g, " ");
    if (normalizeQuery) {
      const pattern = new RegExp(
        `(${normalizeQuery.split(/\s/).map(escapeRegExp).join("|")})`,
        "gi"
      );
      const words = text.split(pattern);
      return createElement(
        "span",
        context.data,
        words.map(function (word) {
          if (pattern.test(word)) {
            return createElement("mark", [word]);
          } else {
            return word;
          }
        })
      );
    } else {
      return createElement("span", context.data, [text]);
    }
  },
};
</script>
