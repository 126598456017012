import IsMobile from "~/src/components/mixins/IsMobile";

export default {
  name: "RootMixin",
  mixins: [IsMobile],
  computed: {
    loggedIn() {
      return this.$auth.loggedIn;
    },
    meId() {
      return this.$auth.userId || null;
    },
    hasRightEmployeeManagement() {
      return this.$auth.hasRight("employee_management");
    },
    hasRightPassportRead() {
      return this.$auth.hasRight("passport_read");
    },
    hasRightPassportManagement() {
      return this.$auth.hasRight("passport_management");
    },
    hasRightPassportManagementDestructive() {
      return this.$auth.hasRight("passport_management_destructive");
    },
    hasRightStatistics() {
      return this.$auth.hasRight("statistics");
    },
  },
  methods: {
    refreshBadges() {
      if (this.$children.length > 0) {
        this.$children[0].refreshBadges();
      }
    },
    jumpTo(route) {
      if (this.altPressed) {
        const resolvedRoute = this.$router.resolve(route);
        window.open(resolvedRoute.href, "_blank", "noopener");
      } else {
        this.$router.push(route);
      }
    },
  },
};
