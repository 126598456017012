<template>
  <div :class="$style.wrap">
    <div :class="$style.left">
      <slot name="actions" />
    </div>
    <app-pager
      :class="$style.center"
      :has-next-page="hasNextPage"
      :has-previous-page="hasPreviousPage"
      center
      show-always
      @next-page="onNextPage"
      @previous-page="onPreviousPage"
    />
    <div :class="$style.right">
      <div :class="$style.results">
        <slot name="results" />
      </div>
      <el-tooltip content="Diese Liste neu vom Server laden">
        <el-button
          circle
          size="medium"
          icon="el-icon-refresh-right"
          @click="onReload"
        />
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import { emptyFn } from "~/src/util/empty-fn";
import AppPager from "~/src/components/common/list/AppPager";

export default {
  name: "DataTableFooter",
  components: { AppPager },
  props: {
    reload: {
      type: Function,
      default: emptyFn,
    },
    hasNextPage: {
      type: Boolean,
      default: false,
    },
    hasPreviousPage: {
      type: Boolean,
      default: false,
    },
    table: {
      type: Object,
      default: null,
    },
  },
  methods: {
    onNextPage() {
      this.$emit("next-page");
      this.scrollToTableTop();
    },
    onPreviousPage() {
      this.$emit("previous-page");
      this.scrollToTableTop();
    },
    onReload() {
      const pro = this.reload();
      const chain = pro instanceof Promise ? pro : Promise.resolve(true);
      chain.finally(this.scrollToTableTop);
    },
    scrollToTableTop() {
      const { table } = this;
      if (table) {
        const el = table.$el || table;
        el.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    },
  },
};
</script>

<style module lang="module.css">
.wrap {
  display: flex;
  align-items: center;
}
.side {
  flex: 1;
}
.left {
  composes: side;
}
.right {
  composes: side;
  text-align: right;
}
.center {
  width: 100px;
}
.results {
  display: inline-block;
  color: #999999;
}
</style>
