export const STJA_COLORS = {
  tuerkis: "#16a1c0",
  signalgruen: "#2a9f70",
  gelbgruen: "#74b342",
  maingruen: "#d6df2a",
  gelb: "#d6df2a",
  orange: "#db9e27",
  signalrot: "#c41440",
  kirschrot: "#c60072",
  purpur: "#870d88",
  flieder: "#601588",
  dunkelblau: "#351788",
  mittelblau: "#1e89cf",
  lichtgruen: "#abd1ce",
  hellgelb: "#fefcd8",
  rosa: "#dea49a",
  hellblau: "#a6c3e9",
};

export const CHART_COLORS = [
  "#1e89cf",
  "#ce8e2d",
  "#2d7df9",
  "#812f4e",
  "#5ab5f2",
  "#87ecec",
  "#a4ecc3",
  "#b9cf88",
  "#cdb14e",
  "#a85f3d",
  "#0045ff",
  "#275bb3",
  "#2c45a4",
  "#302e96",
  "#351788",
  "#481278",
  "#5b0e69",
  "#6d0959",
  "#80054a",
  "#93003a",
];
