const KEY = "enabledFeatureFlags";

export function getAllFeatureFlags() {
  const saved = localStorage.getItem(KEY);
  return saved ? JSON.parse(saved) : [];
}

let cache = {};

export function setAllFeatureFlags(newFlags) {
  localStorage.setItem(KEY, JSON.stringify(newFlags));
  cache = {};
}

function has(feature) {
  if (!cache.hasOwnProperty(feature)) {
    cache[feature] = getAllFeatureFlags().includes(feature);
  }
  return cache[feature];
}

export function hasSdika() {
  return has("sdika");
}
