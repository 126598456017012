function getWindowWidth() {
  if (typeof window !== "undefined") {
    return window.innerWidth;
  }
  return 600;
}

export default {
  name: "IsMobile",
  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    window.addEventListener("orientationchange", this.onResize);
    this.onResize();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener("orientationchange", this.onResize);
  },
  methods: {
    onResize() {
      this.isMobile = getWindowWidth() < 600;
    },
  },
};
