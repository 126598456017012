<template>
  <span>
    <el-tooltip :content="isHuman" :tabindex="-1">
      <el-tag type="info" size="mini" :class="$style.tag">
        {{ isHuman[0] }}
      </el-tag>
    </el-tooltip>
    <el-tooltip :content="genderHuman" :tabindex="-1">
      <el-tag
        :type="person.gender ? 'info' : 'warning'"
        size="mini"
        :class="$style.tag"
      >
        {{ genderHumanAbbrev }}
      </el-tag>
    </el-tooltip>
    <router-link :is="to ? 'router-link' : 'span'" :to="to">
      <query-highlighter
        :query="
          highlightableWordsProvider &&
          highlightableWordsProvider.highlightableWords
        "
        :text="person.firstName"
        :class="emphasizeName ? $style.emphasizeName : null"
      />
      <query-highlighter
        :query="
          highlightableWordsProvider &&
          highlightableWordsProvider.highlightableWords
        "
        :text="person.lastName"
        :class="emphasizeName ? $style.emphasizeName : null"
      />
    </router-link>
    <birthday-label :birthday="person.birthday" />
    <el-tooltip
      v-if="false && person.phone && person.phone.length > 0"
      :tabindex="-1"
    >
      <i class="el-icon-phone" />
      <div slot="content" :class="$style.phoneTooltip">
        <div v-for="(phone, i) in person.phone" :key="i">
          <phone-number :phone="phone" include-info :apollo="$apollo">
            {{ phone }}
          </phone-number>
        </div>
      </div>
    </el-tooltip>
    <country-code
      v-if="person.citizenship"
      :code="person.citizenship"
      tooltip
    />
    <el-tooltip
      v-else
      content="Staatsangehörigkeit nicht bekannt"
      :tabindex="-1"
    >
      <el-tag type="warning" size="mini">?</el-tag>
    </el-tooltip>
    <passport-info v-if="!hidePassport" :passport="person.currentPassport" />
  </span>
</template>

<script>
import {
  PERSON_GENDER_ABBREV,
  PERSON_GENDER_FULL,
  RELATION_IS,
} from "~/src/util/family";
import CountryCode from "~/src/components/common/i18n/CountryCode";
import PhoneNumber from "~/src/components/common/utils/PhoneNumber";
import BirthdayLabel from "~/src/components/families/BirthdayLabel";
import PassportInfo from "~/src/components/passports/PassportInfo";
import QueryHighlighter from "~/src/components/common/utils/QueryHighlighter";

export default {
  name: "FamilyMember",
  components: {
    QueryHighlighter,
    PassportInfo,
    BirthdayLabel,
    PhoneNumber,
    CountryCode,
  },
  inject: {
    highlightableWordsProvider: {
      from: "highlightableWordsProvider",
      default: null,
    },
  },
  props: {
    member: {
      type: Object,
      required: true,
    },
    to: {
      type: [String, Object],
      default: null,
    },
    emphasizeName: {
      type: Boolean,
      default: false,
    },
    hidePassport: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isHuman() {
      const { member } = this;
      return RELATION_IS[member.is] || member.is || "?";
    },
    person() {
      const { member } = this;
      return member;
    },
    hasSpecialAttributes() {
      const { person } = this;
      return person.retired || person.handicapped;
    },
    genderHumanAbbrev() {
      const { person } = this;
      return PERSON_GENDER_ABBREV[person.gender] || "?";
    },
    genderHuman() {
      const { person } = this;
      return PERSON_GENDER_FULL[person.gender] || "Geschlecht nicht angegeben";
    },
  },
};
</script>

<style module lang="module.css">
.tag {
  min-width: 22px;
  text-align: center;
}
.emphasizeName {
  font-weight: bold;
  font-size: 140%;
}
.phoneTooltip {
  font-size: 150%;
}
.phoneTooltip div + div {
  margin-top: 6px;
}
</style>
