import "~/src/util/setup-nonce";
import "es6-promise/auto"; // the Promise polyfill needs to be included unconditionally
import { polyfilled } from "~/src/util/polyfills";
import { configureVue } from "~/src/util/setup-vue";
import App from "~/src/Frontend.vue";
import { getRouter } from "~/src/router";
import { getRoutes } from "~/src/router/frontend-routes";
import { apolloProvider } from "~/src/graphql/graphql";
import { init as authInit, ReactiveAuth } from "~/src/util/auth";
import KeyboardAware from "~/src/components/mixins/KeyboardAware";
import RootMixin from "~/src/components/mixins/RootMixin";
import Vue from "vue";

ReactiveAuth.setRequiredRole({ role: "employee" });
authInit();

document.addEventListener("DOMContentLoaded", () => {
  polyfilled(function () {
    configureVue();
    Vue.prototype.$auth = ReactiveAuth;
    const app = new Vue({
      mixins: [KeyboardAware, RootMixin],
      router: getRouter(getRoutes()),
      apolloProvider,
      render: (h) => h(App),
    });
    app.$mount("#app");
  });
});
