<template>
  <router-link :is="tag" :to="to" :class="buttonClasses">
    <i v-if="icon" :class="icon" /><span v-if="$slots.default"><slot /></span>
  </router-link>
</template>
<script>
export default {
  name: "NavigationButton",
  props: {
    to: {
      type: [String, Object],
      required: true,
    },
    type: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: null,
    },
    plain: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    round: {
      type: Boolean,
      default: false,
    },
    circle: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tag() {
      const { disabled } = this;
      if (disabled) {
        return "span";
      }
      return "router-link";
    },
    buttonClasses() {
      const { type, size, plain, round, circle, disabled } = this;
      const cls = ["el-button"];
      if (type) {
        cls.push(`el-button--${type}`);
      }
      if (size) {
        cls.push(`el-button--${size}`);
      }
      if (plain) {
        cls.push("is-plain");
      }
      if (round) {
        cls.push("is-round");
      }
      if (circle) {
        cls.push("is-circle");
      }
      if (disabled) {
        cls.push("is-disabled");
      }
      return cls;
    },
  },
};
</script>
