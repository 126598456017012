<script>
import CITY_DISTRICTS from "../../../data/city_districts.json";

export function getCityDistrictName(
  number,
  unknown = "unbekannter Stadtteil (%s)"
) {
  if (CITY_DISTRICTS[number]) {
    return `[${number}] ${CITY_DISTRICTS[number]}`;
  } else {
    return unknown.replace("%s", number);
  }
}

export default {
  name: "CityDistrictName",
  functional: true,
  props: {
    number: {
      type: String,
      required: true,
    },
    unknown: {
      type: String,
      default: "unbekannter Stadtteil (%s)",
    },
  },
  render(createElement, context) {
    const { number, unknown } = context.props;
    return createElement("span", getCityDistrictName(number, unknown));
  },
};
</script>
