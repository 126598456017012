export default {
  name: "KeyboardAware",
  data() {
    return {
      shiftPressed: false,
      altPressed: false,
      ctrlPressed: false,
    };
  },
  mounted() {
    document.body.addEventListener("keydown", this.onKeyDown);
    document.body.addEventListener("keyup", this.onKeyUp);
  },
  beforeDestroy() {
    document.body.removeEventListener("keydown", this.onKeyDown);
    document.body.removeEventListener("keyup", this.onKeyUp);
  },
  methods: {
    onKeyDown(e) {
      this.shiftPressed = e.shiftKey;
      this.altPressed = e.altKey;
      this.ctrlPressed = e.ctrlKey;
    },
    onKeyUp() {
      this.shiftPressed = false;
      this.altPressed = false;
      this.ctrlPressed = false;
    },
  },
};

