<template>
  <h2 :class="$style.heading">
    <slot />
    <el-button-group v-if="$slots.actions" size="small" :class="$style.actions">
      <slot name="actions" />
    </el-button-group>
  </h2>
</template>

<script>
export default {
  name: "ListHeading",
};
</script>

<style module lang="module.css">
.heading:not(:first-child) {
  margin-top: 2rem;
}
.actions {
  vertical-align: -9px;
}
</style>
